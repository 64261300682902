<template>
  <el-card>
    <el-form ref="form"
             v-loading="loading"
             :model="form"
             label-width="120px">
      <b-row>
        <b-col md="6">
          <el-form-item label="风险类别"
                        prop="riskType"
                        :rules="[ { required: true, message: '风险类别不能为空', trigger: 'change' }]">
            <el-select v-model="form.riskType"
                       placeholder="请选择风险类别">
              <el-option v-for="(item,index) in riskTypeOptions"
                         :key="index"
                         :label="item"
                         :value="item" />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="风险等级"
                        prop="riskLevel"
                        :rules="[ { required: true, message: '风险类别不能为空', trigger: 'change' }]">
            <el-select v-model="form.riskLevel"
                       placeholder="请选择风险等级">
              <el-option v-for="(item,index) in riskLevelOptions"
                         :key="index"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="关联对象"
                        class="form-item-flex"
                        prop="riskObjectId"
                        :rules="form.riskObjectType == 1 || form.riskObjectType == 2 ? [ { required: true, message: '关联对象不能为空', trigger: 'blur' }] : []">
            <el-select class="mr10"
                       v-model="form.riskObjectType"
                       placeholder="请选择关联对象"
                       @change="changeRiskObject">
              <el-option v-for="(item,index) in riskObjectTypeOptions"
                         :key="index"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
            <el-select v-if="form.riskObjectType == 1 || form.riskObjectType == 2"
                       v-model="form.riskObjectId"
                       placeholder="请选择">
              <el-option v-for="(item,index) in sourceOptionList"
                         :key="index"
                         :label="item.name"
                         :value="item.id" />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item prop="createTime"
                        :rules="[ { required: true, message: '时间不能为空', trigger: 'change' }]"
                        label="产生时间">
            <el-date-picker v-model="form.createTime"
                            format="yyyy-MM-dd HH:ss:mm"
                            placement="bottom-start"
                            value-format="yyyy-MM-dd HH:ss:mm"
                            type="datetime"
                            style="width: 100%;"
                            placeholder="选择日期" />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="处置建议">
            <el-input v-model="form.disposalAdvice"
                      placeholder="请输入内容"
                      rows="10"
                      type="textarea" />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="风险描述">
            <el-input v-model="form.explanation"
                      placeholder="请输入内容"
                      rows="10"
                      type="textarea" />
          </el-form-item>
        </b-col>
      </b-row>

    </el-form>
    <div class="text-center mt20">
      <el-button @click="back">
        取 消
      </el-button>
      <el-button type="primary"
                 :disabled="loading"
                 @click="submitForm">
        保 存
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { GetRiskManageDetail, saveOrUpdateRisk, GetAllRiskTypes, GetAllRiskObjects } from '@/api/risk/risk'
import { GetThirdPartyOptionList, QueryAssetsOptions } from '@/api/enterprise/assest'
export default {
  data () {
    return {
      loading: false,
      form: {
        riskType: '',
        riskLevel: undefined,
        createTime: undefined,
        riskObjectId: undefined,
        explanation: '',
        disposalAdvice: '',
        riskObjectType: 5,
      },
      riskId: Number(this.$route.query.id),
      riskObjects: [],
      riskTypes: [],
      sourceOptionList: [],
      riskTypeOptions: [
        '管理制度', '组织与人员', '隐私声明与告知', '个人信息收集', '个人信息处理', '个人信息存储', '个人信息共享', '个人信息跨境',
        '主体权利响应', '第三方合规', '应急事件响应', '产品隐私保护设计', '数据安全能力', '基础信息安全'
      ],
      riskObjectTypeOptions: [
        { label: 'IT资产', value: 1 },
        { label: '第三方', value: 2 },
        { label: '组织与人员', value: 3 },
        { label: '管理制度', value: 4 },
        { label: '其他', value: 5 },
      ],
      riskLevelOptions: [
        { label: '低', value: 2 },
        { label: '中', value: 3 },
        { label: '高', value: 4 },
        { label: '严重', value: 5 },
      ],
    }
  },
  created() {
    if (this.riskId) {
      this.getRiskManageDetail()
    }
  },
  methods: {
    back() {
      this.$router.push({
        path: '/risk-perception',
      })
    },
    changeRiskObject() {
      this.form.riskObjectId = undefined
      if (this.form.riskObjectType === 1) {
        this.getAssetsOptions()
      }
      if (this.form.riskObjectType === 2) {
        this.getThirdPartyOptions()
      }
    },
    getAssetsOptions() {
      QueryAssetsOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.sourceOptionList = res.data.data
        }
      })
    },
    getThirdPartyOptions() {
      GetThirdPartyOptionList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.sourceOptionList = resData.data
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.riskId) {
            this.form.id = Number(this.riskId)
          }
          if (this.form.riskObjectId === '') {
            this.form.riskObjectId = 0
          }
          this.form.riskObjectId = (this.form.riskObjectType === 3 || this.form.riskObjectType === 4) ? 0 : this.form.riskObjectId
          saveOrUpdateRisk(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
        return true
      })
    },
    getRiskManageDetail () {
      GetRiskManageDetail({ id: Number(this.riskId) }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          for (const i in this.form) {
            this.form[i] = resData.data[i]
          }
          if (this.form.riskObjectType === 1) {
            this.getAssetsOptions()
          }
          if (this.form.riskObjectType === 2) {
            this.getThirdPartyOptions()
          }
        }
      })
    },
  },
  mounted () {
    this.navActiveInit(1, '风险感知', '风险管理')
  },
  destroyed () {
    this.navActiveInit(2, '风险感知', '风险管理', '/risk-perception')
  },
}
</script>

<style>
</style>
